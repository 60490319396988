.c-login-page__form {
  padding-bottom: var(--g-spacing-5x-large);
  padding-top: var(--g-spacing);
}

.c-login-page__register-account-container {
  display: flex;
  font-family: var(--g-font-primary);
  font-weight: var(--g-font-weight-light);
  justify-content: center;
  padding-bottom: var(--g-spacing-2x-large);
  padding-top: var(--g-spacing-2x-large);
}

.c-login-page__register-account-container a {
  color: var(--g-color-brand-tertiary);
  font-weight: var(--g-font-weight-light);
}
