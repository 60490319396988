.c-contact-list-items .c-button-list {
  max-height: 25.65rem;
  position: relative;
  overflow-y: auto;
  text-align: left;
}

.c-contact-list-items .c-button-list button {
  cursor: pointer;
  font-size: var(--g-font-size-small);
  text-align: left;
  background-color: var(--g-color-white);
}

.c-contact-list-items .c-button-list button:hover,
.c-contact-list-items .c-button-list button:focus {
  background-color: var(--g-color-grey-100);
}

.c-contact-list-items .c-button-list button:first-child {
  border-top: none;
}


.c-contact-list-item__count {
  align-items: center;
  background-color: var(--g-color-grey-50);
  border: var(--g-border-primary);
  border-top: none;
  padding: var(--g-spacing-small);
}
