.c-forgot-password-page__form {
  padding-bottom: var(--g-spacing-5x-large);
  padding-top: var(--g-spacing-5x-large);
}

.c-forgot-password-page__return-to-login-container {
  display: flex;
  justify-content: center;
  padding-bottom: var(--g-spacing-2x-large);
  padding-top: var(--g-spacing-2x-large);
}

.c-forgot-password-page__return-to-login-container a {
  color: var(--g-color-brand-tertiary);
  font-weight: 600;
}