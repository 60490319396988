/* stylelint-disable selector-class-pattern */
.c-tsv__icon {
  color: var(--g-color-grey-300);
  height: var(--g-spacing-2x-large);
  width: var(--g-spacing-2x-large);
}

.c-tsv-card {
  width: 100% !important;
  height: auto !important;
}

.c-tsv__tick-icon {
  border-radius: 50%;
  background-color: var(--color-primary);
  color: var(--g-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-tsv__tick-icon svg {
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

.c-tsv__link,
.c-tsv__header {
  font-family: var(--g-font-primary);
  font-size: var(--g-font-size);
}

.c-tsv__link {
  color: var(--color-primary);
  text-decoration: underline;
  font-weight: var(--g-font-weight-light);
}

.c-tsv__header {
  margin: 0;
}

.c-tsv__text {
  font-family: var(--g-font-primary);
  font-weight: var(--g-font-weight-light);
  line-height: 1.18;
}
