.c-smart-code-list___add-button {
    background: transparent;
    border: none;
    cursor: pointer;
    height: var(--g-spacing);
    width: var(--g-spacing);
    padding-top: var(--g-spacing-x-large);
    padding-right: var(--g-spacing-2x-large);
  }

  .c-smart-code-list___add-icon {
    height: var(--g-spacing-2x-large);
    width: var(--g-spacing-2x-large);
  }

  .c-smart-code-list-item__delete{
    
  }