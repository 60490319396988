.c-invited-user-list__add-icon {
  height: var(--g-spacing-2x-large);
  width: var(--g-spacing-2x-large);
}

.c-invited-user-list-items {
  margin: 0;
  max-height: var(--g-invited-user-list-max-height);
  overflow-y: auto;
  padding: 0;
}

.c-invited-user-list-item {
  align-items: center;
  border: var(--g-border-primary);
  border-width: var(--g-spacing-3x-small);
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: var(--g-spacing-x-small) var(--g-spacing);
}

.c-invited-user-list-item:first-child {
  border-top-left-radius: var(--g-border-radius-small);
  border-top-right-radius: var(--g-border-radius-small);
}

.c-invited-user-list-item:not(:first-child) {
  border-top: none;
}

.c-invited-user-list-item__content {
  display: flex;
  align-items: center;
  gap: var(--g-spacing-2x-small);
}

.c-invited-user-list-item__count {
  background-color: var(--g-color-grey-50);
  border-bottom-left-radius: var(--g-border-radius-small);
  border-bottom-right-radius: var(--g-border-radius-small);
  padding: var(--g-spacing);
}

.c-invited-user-list-item__label {
  font-size: var(--g-font-size);
  padding-left: var(--g-spacing-x-small);
}
